<div class="services-we-offer" id="viewMore">
    <div class="services-info-area">
        <div class="header-area">
            <div class="header-font">Services we offer to our patients</div>
            <div class="d-flex flex-row justify-content-center py-3">
                <div class="header-line"></div>
            </div>
        </div>
        <div class="services-card-area mt-4">
            <div class="row" >
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-3 service-card bg-blue-card d-flex"
                     *ngFor="let card of servicesCardList">
                        <div class="card">
                            <div class="card-body">
                                <div class="">
                                    <div class="my-3 hideOnMouseOver">
                                        <img [src]="card.imgUrl" alt="">
                                    </div>
                                    <div class="card-header-text">{{card.header}}</div>
                                    <div class="my-2 pt-2 d-flex flex-row justify-content-center">
                                        <div class="white-underline"></div>
                                    </div>
                                    <div class="card-subHeader hideOnMouseOver font-16 p-2">{{card.subheader}}</div>
                                    <div class="card-subHeader showOnMouseOver font-16 p-2">{{card.mouserOverText}} </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <div>
        <section class="py-3">
            <div class="">
                <div class="row">
                    <div class="col-lg-5 col-md-6 col-sm-12">
                        <div class="animate-box text-box">
                            <h2 class="wow slideInLeft header-font" data-wow-delay="0.15s"
                                style="visibility: visible; animation-delay: 0.15s; animation-name: slideInLeft;">
                                Our providers
                            </h2>
                            <div class="mt-3">
                                <div class="font-18-bold">
                                    Build part time or full time practice online on your virtual clinic
                                </div>
                            </div>
                            <div class="my-3"><div class="header-line"></div></div>
                            <p class="wow slideInLeft talkto-text" data-wow-delay="0.25s"
                                style="visibility: visible; animation-delay: 0.25s; animation-name: slideInLeft;">
                                Global access to patients to provide expert opinions and treat patients
                                online in geographical areas where you have active license to practice medicine
                            </p>
                            <div class="my-3">
                                <div class="d-flex align-center">
                                    <div class="mr-3"><img src="../../assets/home/orange-double-tick.svg" alt=""></div>
                                    <div class="font-16 font-16-bold">How we help our partners</div>
                                </div>
                                <div class="d-flex align-center">
                                    <div class="mr-3"><img src="../../assets/home/orange-double-tick.svg" alt=""></div>
                                    <div class="font-16 font-16-bold">Our benefits for providers</div>
                                </div>
                                <div class="d-flex align-center">
                                    <div class="mr-3"><img src="../../assets/home/orange-double-tick.svg" alt=""></div>
                                    <div class="font-16 font-16-bold">Our Partnerships Policy</div>
                                </div>
                            </div>
                            <div class="button-block group-lg wow fadeIn" data-wow-delay="0.35s"
                            style="visibility: visible; animation-delay: 0.35s; animation-name: fadeIn;">
                                <button class="primary-button">View More</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-6 col-sm-12">
                        <div class="animate-box">
                            <div class="block-img-decoration talkto-img wow slideInRight"
                                style="visibility: visible; animation-name: slideInRight;">
                                <img class="talkto-img" src="../../assets/home/our-provides-div.svg" alt="" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>