<div class="home-area">
    <div class="row home-text">
        <div class="col-lg-6 col-md-6 col-sm-12 left-area">
            <div>
                <div class="cursol-area">
                    <span class="primary">GTCure </span> <span class="secondary">Aims For </span>
                    <span class="primary">Global Telemedicine </span> <span  class="secondary">Access In Striving For </span>
                    <span class="primary">Cure</span>
                </div>
                <div class="cursol-info mt-3">
                    GTCure provides clinical consultation services and expert opinions
                    to patients without requiring an in-person office visit via HIPAA compliant
                    secure audio-video communications. 
                </div>
                <div class="mt-3 d-flex">
                    <div class="mr-4"><button class="primary-button" (click)="openPopup()">Book a consultation</button></div>
                    <div><button class="border-button" (click)="gotoContactUs()">Enquire Now</button></div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="animate-box">
                <div class="block-img-decoration talkto-img wow slideInRight"
                    style="visibility: visible; animation-name: slideInRight;">
                    <img class="talkto-img" src="../../assets/home/Home_banner.svg" alt="" >
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <app-talkto-best-doc [imgUrl]="imgUrl"></app-talkto-best-doc>
</div>
<div>
    <app-services-we-offer></app-services-we-offer>
</div>
<app-setup-online-app></app-setup-online-app>
<app-mobile-app></app-mobile-app>
<app-need-more-info></app-need-more-info>