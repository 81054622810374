import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  @Input() isFromHome: boolean = false;
  contactUsForm: any = {};
  purposeList: any[] = ['Consultation booking Enquiry', 'Doctor Joining Enquiry', 'Payment Enquiry','Other Enquiry']
  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  submit(form:NgForm){
    if(form.invalid){
      return;
    }
    const body:any = {
      purposeOfEmail: this.contactUsForm.purpose,
      email: this.contactUsForm.email,
      name: this.contactUsForm.name,
      message: this.contactUsForm.message
    }
    this.httpClient.post('https://dev.gtcure.com/api/contact',body).subscribe((res:any) => {
      alert("enqirey submitted successfully !");
      window.location.reload();
    })
  }

}
