<div class="home-area">
    <div class="row home-text">
        <div class="col-lg-6 col-md-6 col-sm-12 left-area">
            <div>
                <div class="cursol-area">
                    <span class="secondary">
                        GTCure is  
                    </span>
                    <span class="primary">HIPAA </span>
                    <span class="secondary">compliant telemedicine platform</span>
                </div>
                <div class="cursol-info mt-3">
                    GTCure provides clinical consultation services and expert opinions 
                    to patients without requiring an in-person office visit via HIPAA compliant secure audio-video communications. 
                </div>
                <div class="mt-3 d-flex">
                    <div><button class="primary-button" (click)="gotoContactUs()">Join Us</button></div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="animate-box">
                <div class="block-img-decoration talkto-img wow slideInRight"
                    style="visibility: visible; animation-name: slideInRight;">
                    <img class="talkto-img" src="../../assets/aboutUs/banner.png" alt="" >
                </div>
            </div>
        </div>
    </div>
    <!-- about us -->
    <section class="bg-default section-lg talkto-area">
        <div class="">
            <div class="row talk-text">
                <div class="col-lg-5 col-md-5 col-sm-12">
                    <div class="animate-box">
                        <div class="block-img-decoration talkto-img wow slideInRight"
                            style="visibility: visible; animation-name: slideInRight;">
                            <img class="talkto-img" src="../../assets/aboutUs/aboutUs.svg" alt="" >
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-5 col-sm-12">
                    <div class="animate-box text-box">
                        <div>
                            <h2 class="wow slideInLeft header-font" data-wow-delay="0.15s"
                            style="visibility: visible; animation-delay: 0.15s; animation-name: slideInLeft;">
                            About us
                            </h2>
                            <div class="my-3"><div class="header-line"></div></div>
                            <div class="font-18-bold">Our Vision</div>
                            <p class="wow slideInLeft talkto-text" data-wow-delay="0.25s"
                                style="visibility: visible; animation-delay: 0.25s; animation-name: slideInLeft;">
                                Global access to highest quality and efficient care via 
                                innovations including machine learning and blockchain technology in healthcare
                            </p>
                            <div class="font-18-bold my-2">Our Mission</div>
                            <p class="wow slideInLeft talkto-text" data-wow-delay="0.25s"
                                style="visibility: visible; animation-delay: 0.25s; animation-name: slideInLeft;">
                                Our mission is to provide high quality care by connecting patients with healthcare experts in their own 
                                geographical area and capability to have access to healthcare globally without borders.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-our-founder></app-our-founder>
<div class="what-we-do">
    <div class="what-we-do-area">
        <div class="row">
            <div class="col-md-5 card-area">
                <div class="p-4">
                    <h2 class="wow slideInLeft header-font" data-wow-delay="0.15s"
                    style="visibility: visible; animation-delay: 0.15s; animation-name: slideInLeft;">
                    What we do?
                    </h2>
                    <div class="my-3"><div class="header-line"></div></div>
                    <p class="wow slideInLeft talkto-text font-16" data-wow-delay="0.25s"
                        style="visibility: visible; animation-delay: 0.25s; animation-name: slideInLeft;">
                        GTCure helps patients with low cost, convenient audio-video consultations and helps
                        healthcare providers grow their virtual practice with flexibility and smooth workflow
                        including capability of sending e-prescriptions to nationwide pharmacies and the orders to major laboratories and
                        participating radiology centers. GTCure platform is also capable of machine learning and blockchain technology.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="partnership">
    <div class="partnership-area">
        <div class="d-flex flex-row justify-content-center">
            <div class="header-area ">
                <div class="header-font d-flex flex-row justify-content-center ">Partnerships</div>
                <div class="d-flex flex-row justify-content-center py-3">
                    <div class="header-line"></div>
                </div>
                <div class="d-flex flex-row justify-content-center">
                    <div class="font-16 partnership-subtext">
                        We welcome partnerships that help further our mission and vision to
                        provide high quality healthcare globally with technological innovations,
                        relationships and care coordination. We believe in diversity and equality for better work environment
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="d-flex flex-row justify-content-center talkto-img">
            <img src="../../assets/aboutUs/happy-team-successful.svg" alt="">
        </div>
    </div>
</div>