<div class="footer-area">
    <div class="footer-info">
        <div class="row footer-text-area" >
            <div class="col-lg-3 col-md-6 col-sm-12 pt-4">
                <div>
                    <img src="../../assets/footer/white-logo.svg" alt="">
                </div>
                <div class="font-14 mt-3">Copyright ©{{year}} GT Cure</div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 pt-4">
                <div class="footer-text-header">
                    Office Address
                </div>
                <div class="d-flex mt-3">
                    <div class="mr-2"><img src="../../assets/footer/address-location.svg" alt=""></div>
                    <div class="font-14">
                        GTCure LLC
                        10470 W Cheyenne Ave, Suite 115, #336
                        Las Vegas, NV 89129, USA
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 pt-4">
                <div class="footer-text-header">
                    Contact Details
                </div>
                <div class="d-flex mt-3 align-center">
                    <div class="mr-2"><img src="../../assets/footer/contact-mobile.svg" alt=""></div>
                    <div class="font-14 mt-1">
                        +16095343232
                    </div>
                </div>
                <div class="d-flex mt-2 align-center">
                    <div class="mr-2"><img src="../../assets/footer/contact-email.svg" alt=""></div>
                    <div class="font-14 mt-1">
                        Business@GTCure.com 
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 pt-4">
                <div class="footer-text-header">
                    Follow Us
                </div>
                <div class="d-flex mt-3">
                    <div class="mr-2">
                        <img src="../../assets/footer/facebook.svg" alt="">
                    </div>
                    <div class="mr-2">
                        <img src="../../assets/footer/linkedIn.svg" alt="">
                    </div>
                    <div class="mr-2">
                        <img src="../../assets/footer/instagram.svg" alt="">
                    </div>
                    <div class="mr-2">
                        <img src="../../assets/footer/twitter.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="term-policy d-flex flex-row justify-content-center pt-5">                           
            <div class="mr-2 cursor-pointer" (click)="goto('terms')">Terms of Use</div>
            <div class="mr-2">|</div>
            <div class=" cursor-pointer" (click)="goto('privacy-policy')">Privacy Policy</div>
        </div>
    </div>
</div>