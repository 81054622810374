<div class="mobile-app" id="mobileAppLinkView">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mobile-responsive">
            <div class="animate-box">
                <div class="block-img-decoration talkto-img wow slideInRight"
                    style="visibility: visible; animation-name: slideInRight;">
                    <img class="talkto-img" src="../../assets/home/mobile.svg" alt="" >
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mobile-text-area mobile-responsive">
            <div class="animate-box text-box">
                <h2 class="wow slideInLeft header-font" data-wow-delay="0.15s"
                    style="visibility: visible; animation-delay: 0.15s; animation-name: slideInLeft;">
                    Download our mobile app
                </h2>
                <div class="my-3"><div class="header-line"></div></div>
                <p class="wow slideInLeft talkto-text font-16" data-wow-delay="0.25s"
                    style="visibility: visible; animation-delay: 0.25s; animation-name: slideInLeft;">
                    Schedule an Online appointment with our expert Doctors
                     and get the consultation on the go, with the all-new <span class="primary-color">GTCure app.</span>
                </p>
                <div class="button-block group-lg wow fadeIn d-flex" data-wow-delay="0.35s"
                style="visibility: visible; animation-delay: 0.35s; animation-name: fadeIn;">
                    <div class="mr-2">
                        <img class="talkto-img" src="../../assets/home/google-play-badge.svg" alt="">
                    </div>
                    <div class="">
                        <img class="talkto-img" src="../../assets/home/app-store-badge.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>