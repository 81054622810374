<div class="home-area">
    <div class="row home-text">
        <div class="col-lg-6 col-md-6 col-sm-12 left-area">
            <div>
                <div class="cursol-area">
                    <span class="secondary">
                        Build part time or full time practice online on your virtual clinic
                    </span>
                </div>
                <div class="cursol-info mt-3">
                    Global access to patients to provide expert 
                    opinions and treat patients online in geographical areas where you have active license to practice medicine 
                </div>
                <div class="mt-3 d-flex">
                    <div><button class="border-button" (click)="gotoContactUs()">Enquire Now</button></div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="animate-box">
                <div class="block-img-decoration talkto-img wow slideInRight"
                    style="visibility: visible; animation-name: slideInRight;">
                    <img class="talkto-img" src="../../assets/for-provider/banner.png" alt="" >
                </div>
            </div>
        </div>
    </div>
</div>
<app-gtcure-benfits></app-gtcure-benfits>
<app-start-consulation></app-start-consulation>
<app-mobile-app></app-mobile-app>
<app-need-more-info></app-need-more-info>