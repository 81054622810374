<div class="setup-online">
    
    <div class="setup-online-area row">
        <div class="header-area col-md-12">
            <div class="header-font">Start giving online consultation</div>
            <div class="d-flex flex-row justify-content-center py-3">
                <div class="header-line"></div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 left-area">
            <div  class="steps-area">
                <div class="step" [class.completed]="step === 'step1'">
                    <div class="v-stepper">
                      <div class="circle"></div>
                      <div class="line"></div>
                    </div>
                    <div class="content">
                      <div class="font-18-bold" (click)="stepClicked('step1')">Register</div>
                      <div class="sub-content sub-text-points" *ngIf="step === 'step1'">
                          <div class="orange-dot mr-2"></div>
                          <div>Convenient sign up process with the required documents then approval takes  place from admin  </div>
                      </div>
                    </div>
                </div>
                <div class="step" [class.completed]="step === 'step2'">
                    <div class="v-stepper">
                      <div class="circle"></div>
                      <div class="line"></div>
                    </div>
                    <div class="content">
                      <div class="font-18-bold" (click)="stepClicked('step2')">Setting up calendar</div>
                      <div class="sub-content " *ngIf="step === 'step2'">
                          <div class="sub-text-points">
                            <div class="orange-dot mr-2"></div>
                            <div class="width-80">
                                Providers set up their customized online calendar, then review patient's intake form of each patient any given day.
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="step" [class.completed]="step === 'step3'">
                    <div class="v-stepper">
                      <div class="circle"></div>
                      <div class="line"></div>
                    </div>
                    <div class="content">
                      <div class="font-18-bold" (click)="stepClicked('step3')">Start the consultation</div>
                      <div class="sub-content" *ngIf="step === 'step3'">
                        <div class="sub-text-points">
                            <div class="orange-dot mr-2"></div>
                            <div class="width-80">
                                Consult a Doctor with the secure audio-video consultation 
                            </div>
                          </div>
                          <div class="sub-text-points">
                            <div class="orange-dot mr-2"></div>
                            <div class="width-80">
                                Get necessary medications and lab orders (if needed) sent out to
                                appropriate pharmacy or lab of your choice and review results once available.
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 right-area">
            <div class="animate-box">
                <div class="block-img-decoration talkto-img wow slideInRight"
                    style="visibility: visible; animation-name: slideInRight;">
                    <img class="talkto-img" *ngIf="step === 'step3'" src="../../../assets/for-provider/consult-doctor.svg" alt="" >
                    <img class="talkto-img" *ngIf="step === 'step1'" src="../../../assets/for-provider/Register.svg" alt="" >
                    <img class="talkto-img" *ngIf="step === 'step2'" src="../../../assets/for-provider/calendar.svg" alt="" >
                </div>
            </div>
        </div>
</div>