<div class="setup-online">
    
    <div class="setup-online-area row">
        <div class="col-lg-4 col-md-4 col-sm-12 left-area">
            <div class="animate-box text-box">
                <div>
                    <h2 class="wow slideInLeft header-font" data-wow-delay="0.15s"
                    style="visibility: visible; animation-delay: 0.15s; animation-name: slideInLeft;">
                    Our Founder
                    </h2>
                    <div class="my-3"><div class="header-line"></div></div>
                    <p class="font-18-bold">Dr. Shamail Butt</p>
                    <p class="font-16 designation">MD, Founder and CEO GTCure </p>
                    <p class="wow slideInLeft talkto-text font-16" data-wow-delay="0.25s"
                        style="visibility: visible; animation-delay: 0.25s; animation-name: slideInLeft;">
                        GTCure is HIPAA compliant telemedicine platform founded by Shamail Butt,
                        MD. Dr Dr Shamail has vast clinical experience in healthcare industry in US.
                         He is Hematologist and medical oncologist by specialty. 
                    </p>
                    <p class="wow slideInLeft talkto-text font-16" data-wow-delay="0.25s"
                        style="visibility: visible; animation-delay: 0.25s; animation-name: slideInLeft;">
                        Dr Shamail realized the health industry needs better
                        care coordination and the patients should have global access to healthcare without borders.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 right-area">
            <div class="animate-box">
                <div class="block-img-decoration talkto-img wow slideInRight"
                    style="visibility: visible; animation-name: slideInRight;">
                    <img class="talkto-img" src="../../../assets/aboutUs/our-founder.svg" alt="" >
                </div>
            </div>
        </div>
</div>