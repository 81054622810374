<div class="setup-online">
    <div class="setup-online-area">
        <div class="header-area">
            <div class="header-font">Privacy policy</div>
            <div class="py-3">
                <div class="header-line"></div>
            </div>
        </div>
        <div class="info-area mt-3">
            <div class="header-sub-font">Privacy Policy</div>
            <div class="font-16 mt-2">
                Our Privacy Policy was last updated on June 01, 2022 
            </div>
            <div class="font-16 mt-2">
                This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about Your privacy rights and how the law protects
                You.
            </div>
            <div class="font-16 mt-2">
                This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about Your privacy rights and how the law protects
                You.
            </div>
            <div class="header-sub-font mt-2 ">Interpretation and Definitions</div>
            <div class="font-18-bold mt-2">Interpretation</div>
            <div class="font-16 mt-2">
                The words of which the initial letter is capitalized have meanings defined under the following conditions.
                The following definitions shall have the same meaning regardless of whether they appear in singular or in
                plural.
            </div>
            <div class="font-18-bold mt-2">Definitions</div>
            <div class="font-16 mt-2">For the purposes of this Privacy Policy:</div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Account" means a unique account created for You to access our Service or parts of our Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Business", for the purpose of the Nevada Privacy Law (SB-220), refers to the Company as the
                        legal entity that collects Consumers's personal information and determines the purposes and means
                        of the processing of Consumer's personal information, or on behalf of which such information is
                        collected and that alone, or jointly with others, determines the purposes and means of the
                        processing of consumer's, personal information, that does business in the State of Nevada.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Company" (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
                        GTCure LLC
                        <div class="">
                            For the purpose of the GDPR, the Company is the Data Controller.
                        </div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Country" refers to United States of America
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Consumer", for the purpose of the Nevada Privacy Law (SB-220), means a natural person who is
                        a Nevada resident. A resident, as defined in the law, includes (1) every individual who is in the USA
                        for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the
                        USA who is outside the USA for a temporary or transitory purpose.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Cookies" are small files that are placed on Your computer, mobile device or any other device by a
                        website, containing the details of Your browsing history on that website among its many uses.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Data Controller", for the purposes of the GDPR (General Data Protection Regulation), refers to the
                        Company as the legal person which alone or jointly with others determines the purposes and means
                        of the processing of Personal Data.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Device" means any device that can access the Service such as a computer, a cell phone or a
                        digital tablet.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Do Not Track" (DNT)” is a concept that has been promoted by US regulatory authorities, in
                        particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and
                        implement a mechanism for allowing internet users to control the tracking of their online activities
                        across websites.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Personal Data" is any information that relates to an identified or identifiable individual.
                        <div class="mt-2">
                            For the purposes of GDPR, Personal Data means any information relating to You such as a name,
                            an identification number, location data, online identifier or to one or more factors specific to the
                            physical, physiological, genetic, mental, economic, cultural or social identity.
                        </div>
                        <div class="mt-2">
                            For the purposes of the Nevada Privacy Law (SB-220), Personal Data means any information that
                            identifies, relates to, describes or is capable of being associated with, or could reasonably be linked,
                            directly or indirectly, with You.
                        </div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Sale", for the purpose of the Nevada Privacy Law (SB-220), means selling, renting, releasing,
                        disclosing, disseminating, making available, transferring, or otherwise communicating orally, in
                        writing, or by electronic or other means, a Consumer's personal information to another business or a
                        third party for monetary or other valuable consideration.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Service" refers to the Website.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Service Provider" means any natural or legal person who processes the data on behalf of the
                        Company. It refers to third-party companies or individuals employed by the Company to facilitate the
                        Service, to provide the Service on behalf of the Company, to perform services related to the Service
                        or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR,
                        Service Providers are considered Data Processors.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Usage Data" refers to data collected automatically, either generated by the use of the Service or
                        from the Service infrastructure itself (for example, the duration of a page visit).
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Website" refers to <a href="www.GTCure.com">www.GTCure.com</a> ,
                         accessible from <a href="https://www.gtcure.com/">https://www.gtcure.com/</a>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "You" means the individual accessing or using the Service, or the company, or other legal entity on
                        behalf of which such individual is accessing or using the Service, as applicable.
                        <div class="mt-2">
                            Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or
                            as the User as you are the individual using the Service.
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-sub-font mt-3">Collecting and Using Your Personal Data</div>
            <div class="font-18-bold mt-2">Types of Data Collected</div>
            <div class="font-18-bold mt-2">Personal Data</div>
            <div class="font-16 mt-2">
                While using Our Service, We may ask You to provide Us with certain personally identifiable information that
                can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Email address
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        First name and last name
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Phone number
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Address, State, Province, ZIP/Postal code, City
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Usage Data
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Social Security Number or National Identification Number
                    </div>
                </div>
            </div>
            <div class="font-18-bold mt-2">Usage Data</div>
            <div class="font-16 mt-2">
                Usage Data may include information such as Your Device&#39;s Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the
                time spent on those pages, unique device identifiers and other diagnostic data.
            </div>
            <div class="font-16 mt-2">
                When You access the Service by or through a mobile device, We may collect certain information
                automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique
                ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet
                browser You use, unique device identifiers and other diagnostic data.
            </div>
            <div class="font-16 mt-2">
                We may also collect information that Your browser sends whenever You visit our Service or when You
                access the Service by or through a mobile device.
            </div>
            <div class="font-18-bold mt-2">Tracking Technologies and Cookies</div>
            <div class="font-16 mt-2">
                We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
                information. Tracking technologies used are beacons, tags, and scripts to collect and track information and
                to improve and analyze Our Service. The technologies We use may include:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct
                        Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do
                        not accept Cookies, You may not be able to use some parts of our Service. Unless you have
                        adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Web Beacons. Certain sections of our Service and our emails may contain small electronic files
                        known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit
                        the Company, for example, to count users who have visited those pages or opened an email and for
                        other related website statistics (for example, recording the popularity of a certain section and
                        verifying system and server integrity).
                    </div>
                </div>
            </div>
            <div class="font-16 mt-2">
                Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer
                or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web
                browser.
            </div>
            <div class="font-16 mt-2">
                We use both Session and Persistent Cookies for the purposes set out below:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">Necessary / Essential Cookies</div>
                        <div class="font-16 mt-2">Type: Session Cookies</div>
                        <div class="font-16 mt-2">Administered by: Us</div>
                        <div class="font-16 mt-2">
                            Purpose: These Cookies are essential to provide You with services available through the Website
                            and to enable You to use some of its features. They help to authenticate users and prevent
                            fraudulent use of user accounts. Without these Cookies, the services that You have asked for
                            cannot be provided, and We only use these Cookies to provide You with those services.
                        </div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">Cookies Policy / Notice Acceptance Cookies</div>
                        <div class="font-16 mt-2">Type: Session Cookies</div>
                        <div class="font-16 mt-2">Administered by: Us</div>
                        <div class="font-16 mt-2">
                            Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
                        </div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">Functionality Cookies</div>
                        <div class="font-16 mt-2">Type: Session Cookies</div>
                        <div class="font-16 mt-2">Administered by: Us</div>
                        <div class="font-16 mt-2">
                            Purpose: These Cookies allow us to remember choices You make when You use the Website, such
                            as remembering your login details or language preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to avoid You having to re-enter your preferences
                            every time You use the Website.
                        </div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">Tracking and Performance Cookies</div>
                        <div class="font-16 mt-2">Type: Session Cookies</div>
                        <div class="font-16 mt-2">Administered by: Third Parties</div>
                        <div class="font-16 mt-2">
                            Purpose: These Cookies are used to track information about traffic to the Website and how users
                            use the Website. The information gathered via these Cookies may directly or indirectly identify you
                            as an individual visitor. This is because the information collected is typically linked to a
                            pseudonymous identifier associated with the device you use to access the Website. We may also
                            use these Cookies to test new pages, features or new functionality of the Website to see how our
                            users react to them.
                        </div>
                    </div>
                </div>
            </div>
            <div class="font-16 mt-2">
                For more information about the cookies, we use and your choices regarding cookies, please visit our
                Cookies Policy or the Cookies section of our Privacy Policy.
            </div>
            <div class="header-sub-font mt-3">Use of Your Personal Data</div>
            <div class="font-16 mt-2">
                The Company may use Personal Data for the following purposes:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To provide and maintain our Service, including to monitor the usage of our Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To manage Your Account: to manage Your registration as a user of the Service. The Personal
                        Data You provide can give You
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        For the performance of a contract: the development, compliance and undertaking of the purchase
                        contract for the products, items or services You have purchased or of any other contract with Us
                        through the Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of
                        electronic communication, such as a mobile application&#39;s push notifications regarding updates or
                        informative communications related to the functionalities, products or contracted services, including
                        the security updates, when necessary or reasonable for their implementation.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To provide You with news, special offers and general information about other goods, services and
                        events which we offer that are similar to those that you have already purchased or enquired about
                        unless You have opted not to receive such information.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To manage Your requests: To attend and manage Your requests to Us.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        For business transfers: We may use Your information to evaluate or conduct a merger,
                        divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our
                        assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in
                        which Personal Data held by Us about our Service users is among the assets transferred.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        For other purposes: We may use Your information for other purposes, such as data analysis,
                        identifying usage trends, determining the effectiveness of our promotional campaigns and to
                        evaluate and improve our Service, products, services, marketing and your experience.
                    </div>
                </div>
            </div>
            <div class="font-16 mt-2">
                We may share Your personal information in the following situations:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        With Service Providers: We may share Your personal information with Service Providers to
                        monitor and analyze the use of our Service, for payment processing, to contact You.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        For business transfers: We may share or transfer Your personal information in connection with, or
                        during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a
                        portion of Our business to another company.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        With Affiliates: We may share Your information with Our affiliates, in which case we will require
                        those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other
                        subsidiaries, joint venture partners or other companies that We control or that are under common
                        control with Us.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        With business partners: We may share Your information with Our business partners to offer You
                        certain products, services or promotions.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        With other users: when You share personal information or otherwise interact in the public areas
                        with other users, such information may be viewed by all users and may be publicly distributed
                        outside.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        With Your consent: We may disclose Your personal information for any other purpose with Your
                        consent.
                    </div>
                </div>
            </div>
            <div class="header-sub-font mt-3">Retention of Your Personal Data</div>
            <div class="font-16 mt-2">
                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
                legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
                disputes, and enforce our legal agreements and policies.
            </div>
            <div class="font-16 mt-2">
                The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
                for a shorter period of time, except when this data is used to strengthen the security or to improve the
                functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            </div>
            <div class="header-sub-font mt-3">Transfer of Your Personal Data</div>
            <div class="font-16 mt-2">
                Your information, including Personal Data, is processed at the Company&#39;s operating offices and in any
                other places where the parties involved in the processing are located. It means that this information may be
                transferred to — and maintained on — computers located outside of Your state, province, country or other
                governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
            </div>
            <div class="font-16 mt-2">
                Your consent to this Privacy Policy followed by Your submission of such information represents Your
                agreement to that transfer.
            </div>
            <div class="font-16 mt-2">
                The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
                accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
                or a country unless there are adequate controls in place including the security of Your data and other
                personal information.
            </div>
            <div class="header-sub-font mt-3">Disclosure of Your Personal Data</div>
            <div class="font-18-bold mt-2"> Business Transactions</div>
            <div class="font-16 mt-2">
                If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
                We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
                Policy.
            </div>
            <div class="font-18-bold mt-2">Law enforcement</div>
            <div class="font-16 mt-2">
                Under certain circumstances, the Company may be required to disclose Your Personal Data if required to
                do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
            </div>
            <div class="font-18-bold mt-2">Other legal requirements</div>
            <div class="font-16 mt-2">
                The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Comply with a legal obligation
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Protect and defend the rights or property of the Company
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Prevent or investigate possible wrongdoing in connection with the Service
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Protect the personal safety of Users of the Service or the public
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Protect against legal liability
                    </div>
                </div>
            </div>
            <div class="header-sub-font mt-3">Security of Your Personal Data</div>
            <div class="font-16 mt-2">
                The security of Your Personal Data is important to Us, but remember that no method of transmission over
                the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
                acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
            </div>
            <div class="header-sub-font mt-3">Detailed Information on the Processing of Your Personal Data</div>
            <div class="font-16 mt-2">
                The Service Providers We use may have access to Your Personal Data. These third-party vendors collect,
                store, use, process and transfer information about Your activity on Our Service in accordance with their
                Privacy Policies.
            </div>
            <div class="font-18-bold mt-2">Analytics</div>
            <div class="font-16 mt-2">
                We may use third-party Service providers to monitor and analyze the use of our Service.
            </div>
            <div class="font-18-bold mt-2">Email Marketing</div>
            <div class="font-16 mt-2">
                We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
                other information that may be of interest to You. You may opt-out of receiving any, or all, of these
                communications from Us by following the unsubscribe link or instructions provided in any email We send or
                by contacting Us.
                <div class="mt-2">
                    <a href="Support@GTCure.com">Support@GTCure.com</a>
                </div>
            </div>
            <div class="font-18-bold mt-2">Payments</div>
            <div class="font-16 mt-2">
                We may provide paid products and/or services within the Service. In that case, we may use third-party
                services for payment processing (e.g. payment processors such as Paypal).
            </div>
            <div class="font-16 mt-2">
                We will not store or collect Your payment card details. That information is provided directly to Our third-
                party payment processors whose use of Your personal information is governed by their Privacy Policy.
                These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover.
                PCI-DSS requirements help ensure the secure handling of payment information.
            </div>
            <div class="header-sub-font mt-3">GDPR Privacy Policy</div>
            <div class="font-18-bold mt-2">Legal Basis for Processing Personal Data under GDPR</div>
            <div class="font-16 mt-2">
                We may process Personal Data under the following conditions:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Consent: You have given Your consent for processing Personal Data for one or more specific
                        purposes. Comply with a legal obligation
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Performance of a contract: Provision of Personal Data is necessary for the performance of an
                        agreement with You and/or for any pre-contractual obligations thereof.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or of
                        another natural person.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Public interests: Processing Personal Data is related to a task that is carried out in the public
                        interest or in the exercise of official authority vested in the Company.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate
                        interests pursued by the Company.
                    </div>
                </div>
            </div>
            <div class="font-16 mt-2">
                In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing,
                and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a
                requirement necessary to enter into a contract.
            </div>
            <div class="font-18-bold mt-2">Your Rights under the GDPR</div>
            <div class="font-16 mt-2">
                The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can
                exercise Your rights.
            </div>
            <div class="font-16 mt-2">
                You have the right under this Privacy Policy, and by law if You are within the EU, to:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Request access to Your Personal Data. The right to access, update or delete the information We
                        have on You. Whenever made possible, you can access, update or request deletion of Your
                        Personal Data directly within Your account settings section. If you are unable to perform these
                        actions yourself, please contact Us to assist You. This also enables You to receive a copy of the
                        Personal Data We hold about You.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Request correction of the Personal Data that We hold about You. You have the right to have
                        any incomplete or inaccurate information We hold about You corrected.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Object to processing of Your Personal Data. This right exists where We are relying on a
                        legitimate interest as the legal basis for Our processing and there is something about Your
                        particular situation, which makes You want to object to our processing of Your Personal Data on this
                        ground. You also have the right to object where We are processing Your Personal Data for direct
                        marketing purposes.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Request erasure of Your Personal Data. You have the right to ask Us to delete or remove
                        Personal Data when there is no good reason for Us to continue processing it.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Request the transfer of Your Personal Data. We will provide to You, or to a third-party You have
                        chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note
                        that this right only applies to automated information which You initially provided consent for Us to
                        use or where We used the information to perform a contract with You.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Withdraw Your consent. You have the right to withdraw Your consent on using your Personal
                        Data. If You withdraw Your consent, We may not be able to provide You with access to certain
                        specific functionalities of the Service.
                    </div>
                </div>
            </div>
            <div class="font-18-bold mt-2">Exercising of Your GDPR Data Protection Rights</div>
            <div class="font-16 mt-2">
                You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please
                note that we may ask You to verify Your identity before responding to such requests. If You make a
                request, We will try our best to respond to You as soon as possible.
            </div>
            <div class="font-16 mt-2">
                You have the right to complain to a Data Protection Authority about Our collection and use of Your
                Personal Data. For more information, if You are in the
            </div>
            <div class="header-sub-font mt-3">Nevada Privacy Law (SB-220) Privacy Policy</div>
            <div class="font-16 mt-2">
                This privacy notice section for Nevada residents supplements the information contained in Our Privacy
                Policy and it applies solely to all visitors, users, and others who reside in the State of Nevada.
            </div>
            <div class="font-18-bold mt-2">Categories of Personal Information Collected</div>
            <div class="font-16 mt-2">
                We collect information that identifies, relates to, describes, references, is capable of being associated with,
                or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a
                list of categories of personal information which we may collect or may have been collected from Nevada
                residents within the last twelve (12) months.
            </div>
            <div class="font-16 mt-2">
                Please note that the categories and examples provided in the list below are those defined in the Nevada
                Privacy Law (SB-220). This does not mean that all examples of that category of personal information were
                in fact collected by Us, but reflects our good faith belief to the best of our knowledge that some of that
                information from the applicable category may be and may have been collected. For example, certain
                categories of personal information would only be collected if You provided such personal information
                directly to Us.
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">Category A: Identifiers.</div>
                        <div class="font-16 mt-2">
                            Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet
                            Protocol address, email address, account name, driver&#39;s license number, passport number, or other
                            similar identifiers.
                        </div>
                        <div class="font-16 mt-2">Collected: Yes.</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category B: Personal information categories listed in the Nevada Customer Records statute
                            or federal law
                        </div>
                        <div class="font-16 mt-2">
                            Examples: A name, signature, Social Security number, physical characteristics or description,
                            address, telephone number, passport number, driver&#39;s license or state identification card number,
                            insurance policy number, education, employment, employment history, bank account number, credit
                            card number, debit card number, or any other financial information, medical information, or health
                            insurance information. Some personal information included in this category may overlap with other
                            categories.
                        </div>
                        <div class="font-16 mt-2">Collected: Yes.</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category C: Protected Health Information classification characteristics under Nevada or
                            federal law.
                        </div>
                        <div class="font-16 mt-2">Collected: Yes.</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category D: Commercial information.
                        </div>
                        <div class="font-16 mt-2">
                            Examples: Records and history of products or services purchased or considered.
                        </div>
                        <div class="font-16 mt-2">Collected: No.</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category E: Biometric information.
                        </div>
                        <div class="font-16 mt-2">
                            Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used
                            to extract a template or other identifier or identifying information, such as, fingerprints, faceprints,
                            and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or
                            exercise data.
                        </div>
                        <div class="font-16 mt-2">Collected: No.</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category F: Internet or other similar network activity.
                        </div>
                        <div class="font-16 mt-2">
                            Examples: Interaction with our Service or advertisement.
                        </div>
                        <div class="font-16 mt-2">Collected: Yes.</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category G: Geolocation data.
                        </div>
                        <div class="font-16 mt-2">
                            Examples: Approximate physical location.
                        </div>
                        <div class="font-16 mt-2">Collected: Yes</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category H: Sensory data.
                        </div>
                        <div class="font-16 mt-2">
                            Examples: Audio, electronic, visual, thermal, olfactory, or similar information.
                        </div>
                        <div class="font-16 mt-2">Collected: No.</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category I: Professional or employment-related information.
                        </div>
                        <div class="font-16 mt-2">
                            Examples: Current or past job history or performance evaluations.
                        </div>
                        <div class="font-16 mt-2">Collected: No.</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category J: Non-public education information (per the Family Educational Rights and
                            Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
                        </div>
                        <div class="font-16 mt-2">Collected: No.</div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        <div class="font-16-bold">
                            Category K: Inferences drawn from other personal information.
                        </div>
                        <div class="font-16 mt-2">
                            Examples: Profile reflecting a person&#39;s preferences, characteristics, psychological trends,
                            predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
                        </div>
                        <div class="font-16 mt-2">Collected: No.</div>
                    </div>
                </div>
            </div>
            <div class="font-16 mt-2">
                Under the Nevada Privacy Law (SB-220), personal information does not include:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Publicly available information from government records
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Deidentified or aggregated consumer information
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Information excluded from the the Nevada Privacy Law (SB-220) scope, such as:
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Health or medical information covered by the Health Insurance Portability and Accountability Act of
                        1996 (HIPAA) or clinical trial data
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Personal Information covered by certain sector-specific privacy laws, including the Fair Credit
                        Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or the Driver&#39;s Privacy Protection Act of
                        1994
                    </div>
                </div>
            </div>
            <div class="font-18-bold mt-2">
                Sources of Personal Information
            </div>
            <div class="font-16 mt-2">
                We obtain the categories of personal information listed above from the following categories of sources:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Directly from You. For example, from the forms You complete on our Service, preferences You
                        express or provide through our Service, or from Your purchases on our Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Indirectly from You. For example, from observing Your activity on our Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Automatically from You. For example, through cookies We or our Service Providers set on Your
                        Device as You navigate through our Service.
                    </div>
                </div>
            </div>
            <div class="font-18-bold mt-2">
                Use of Personal Information for Business Purposes or Commercial Purposes
            </div>
            <div class="font-16 mt-2">
                We may use or disclose personal information We collect for &quot;business purposes&quot; or &quot;commercial purposes&quot;
                which may include the following examples:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To operate our Service and provide You with our Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To provide You with support and to respond to Your inquiries, including to investigate and address
                        Your concerns and monitor and improve our Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Automatically from You. For example, through cookies We or our Service Providers set on Your
                        Device as You navigate through our Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To fulfill or meet the reason You provided the information. For example, if You share Your contact
                        information to ask a question about our Service, We will use that personal information to respond to
                        Your inquiry. If You provide Your personal information to purchase a product or service, We will use
                        that information to process Your payment and facilitate delivery.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To respond to law enforcement requests and as required by applicable law, court order, or
                        governmental regulations.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        As described to You when collecting Your personal information or as otherwise set forth in the
                        Nevada Privacy Law (SB-220).
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        For internal administrative and auditing purposes.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity,
                        including, when necessary, to prosecute those responsible for such activities.
                    </div>
                </div>
            </div>
            <div class="font-16 mt-2">
                Please note that the examples provided above are illustrative and not intended to be exhaustive. For more
                details on how we use this information, please refer to the &quot;Use of Your Personal Data&quot; section.
            </div>
            <div class="font-16 mt-2">
                If We decide to collect additional categories of personal information or use the personal information We
                collected for materially different, unrelated, or incompatible purposes We will update this Privacy Policy.
            </div>
            <div class="font-18-bold mt-2">
                Share of Personal Information
            </div>
            <div class="font-16 mt-2">
                We may share Your personal information identified in the above categories with the following categories of
                third parties:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Service Providers
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Payment processors
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Our affiliates
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Our business partners
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Third party vendors to whom You or Your agents authorize Us to disclose Your personal information
                        in connection with products or services We provide to You
                    </div>
                </div>
                <!-- <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        As described to You when collecting Your personal information or as otherwise set forth in the
                        Nevada Privacy Law (SB-220).
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        For internal administrative and auditing purposes.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity,
                        including, when necessary, to prosecute those responsible for such activities.
                    </div>
                </div> -->
            </div>
            <div class="font-18-bold mt-2">
                Your Rights under the Nevada Privacy Law (SB-220)
            </div>
            <div class="font-16 mt-2">
                The Nevada Privacy Law (SB-220) residents with specific rights regarding their personal information. If You
                are a resident of Nevada, You have the following rights:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        The right to notice. You have the right to be notified which categories of Personal Data are being
                        collected and the purposes for which the Personal Data is being used.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        The right to request. You have the right to request that We disclose information to You about Our
                        collection, use, sale, disclosure for business purposes and share of personal information. Once We
                        receive and confirm Your request, We will disclose to You:
                        <div class="mt-2">
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    The categories of personal information We collected about You
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    The categories of sources for the personal information We collected about You
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Our business or commercial purpose for collecting or selling that personal information
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    The categories of third parties with whom We share that personal information
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    The specific pieces of personal information We collected about You
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    If we sold Your personal information or disclosed Your personal information for a business
                                    purpose, We will disclose to You:
                                    <div class="mt-2">
                                        <div class="sub-text-points mt-3">
                                            <div class="orange-dot mr-3"></div>
                                            <div class="width-80">
                                                The categories of personal information categories sold
                                            </div>
                                        </div>
                                        <div class="sub-text-points mt-3">
                                            <div class="orange-dot mr-3"></div>
                                            <div class="width-80">
                                                The categories of personal information categories disclosed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        The right to say no to the sale of Personal Data (opt-out). You have the right to direct Us to not
                        sell Your personal information. To submit an opt-out request please contact Us.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        The right to delete Personal Data. You have the right to request the deletion of Your Personal
                        Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and
                        direct Our Service Providers to delete) Your personal information from our records, unless an
                        exception applies. We may deny Your deletion request if retaining the information is necessary for
                        Us or Our Service Providers to:
                        <div class="mt-2">
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Complete the transaction for which We collected the personal information, provide a good or
                                    service that You requested, take actions reasonably anticipated within the context of our
                                    ongoing business relationship with You, or otherwise perform our contract with You.
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity,
                                    or prosecute those responsible for such activities.
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Debug products to identify and repair errors that impair existing intended functionality.
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Exercise free speech, ensure the right of another consumer to exercise their free speech
                                    rights, or exercise another right provided for by law.
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Engage in public or peer-reviewed scientific, historical, or statistical research in the public
                                    interest that adheres to all other applicable ethics and privacy laws, when the information&#39;s
                                    deletion may likely render impossible or seriously impair the research&#39;s achievement, if You
                                    previously provided informed consent.
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Enable solely internal uses that are reasonably aligned with consumer expectations based
                                    on Your relationship with Us.
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Comply with a legal obligation.
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Make other internal and lawful uses of that information that are compatible with the context
                                    in which You provided it.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        The right not to be discriminated against. You have the right not to be discriminated against for
                        exercising any of Your consumer&#39;s rights, including by:
                        <div class="mt-2">
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Denying goods or services to You
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Charging different prices or rates for goods or services, including the use of discounts or
                                    other benefits or imposing penalties
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Providing a different level or quality of goods or services to You
                                </div>
                            </div>
                            <div class="sub-text-points mt-3">
                                <div class="orange-dot mr-3"></div>
                                <div class="width-80">
                                    Suggesting that You will receive a different price or rate for goods or services or a different
                                    level or quality of goods or services
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="font-18-bold mt-2">
                Do Not Sell My Personal Information
            </div>
            <div class="font-16 mt-2">
                You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a
                verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right
                to opt-out, please contact Us.
            </div>
            <div class="font-16 mt-2">
                Please note that any opt out is specific to the browser You use. You may need to opt out on every browser
                that You use.
            </div>
            <div class="font-16 mt-2">
                The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you
                change browsers or delete the cookies saved by your browser, You will need to opt out again.
            </div>
            <div class="font-18-bold mt-2">
                Mobile Devices
            </div>
            <div class="font-16 mt-2">
                Your mobile device may give You the ability to opt out of the use of information about the apps You use in
                order to serve You ads that are targeted to Your interests:
            </div>
            <div class="font-16 mt-2">
                You can also stop the collection of location information from Your mobile device by changing the
                preferences on Your mobile device.
            </div>
            <div class="font-16 mt-2">
                Our Service does not respond to Do Not Track signals.
            </div>
            <div class="font-16 mt-2">
                However, some third party websites do keep track of Your browsing activities. If You are visiting such
                websites, You can set Your preferences in Your web browser to inform websites that You do not want to be
                tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.
            </div>
            <div class="header-sub-font mt-3">
                Children&#39;s Privacy
            </div>
            <div class="font-16 mt-2">
                If We need to rely on consent as a legal basis for processing Your information and Your country requires
                consent from a parent, We may require Your parent&#39;s consent before We collect and use that information.
            </div>
            <div class="header-sub-font mt-3">
                Links to Other Websites
            </div>
            <div class="font-16 mt-2">
                Our Service may contain links to other websites that are not operated by Us. If You click on a third party
                link, You will be directed to that third party&#39;s site. We strongly advise You to review the Privacy Policy of
                every site You visit.
            </div>
            <div class="font-16 mt-2">
                We have no control over and assume no responsibility for the content, privacy policies or practices of any
                third party sites or services.
            </div>
            <div class="header-sub-font mt-3">
                Changes to this Privacy Policy
            </div>
            <div class="font-16 mt-2">
                We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy Policy on this page.
            </div>
            <div class="font-16 mt-2">
                We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.
            </div>
            <div class="font-16 mt-2">
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
                are effective when they are posted on this page..
            </div>
            <div class="header-sub-font mt-3">
                Contact Us
            </div>
            <div class="font-16 mt-2">
                If you have any questions about this Privacy Policy, You can contact us:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        By visiting this page on our website: <a href="https://www.gtcure.com/contact-us">https://www.gtcure.com/contact-us</a>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        By sending us an email: <a href="Business@GTCure.com">Business@GTCure.com</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>