import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-founder',
  templateUrl: './our-founder.component.html',
  styleUrls: ['./our-founder.component.scss']
})
export class OurFounderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
