<div class="contact-us">
    <div class="contact-us-area">
        <div class="header-area">
            <div>
                <div class="header-font">Getting touch</div>
                <div class="d-flex flex-row justify-content-center py-3">
                    <div class="header-line"></div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-5">
                <div class="contact-us-card mb-3">
                    <div class="p-5">
                        <div class="card-primary-title">GT Cure, USA</div>
                        <div class="d-flex align-center pt-4">
                            <div class="pr-3"><img src="../../assets/contact-us/address.png" alt=""></div>
                            <div>
                                <div class="font-16-bold">
                                    Address 
                                </div>
                                <div class="font-16 pt-2">
                                    GTCure LLC <br>
                                    10470 W Cheyenne Ave, Suite 115, <br>
                                    #336 Las Vegas, NV 89129, USA
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-center pt-3">
                            <div class="pr-3"><img src="../../assets/contact-us/call.png" alt=""></div>
                            <div>
                                <div class="font-16-bold">
                                    Call 
                                </div>
                                <div class="font-16 pt-2">
                                    +16095343232
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-center pt-3">
                            <div class="pr-3"><img src="../../assets/contact-us/email.png" alt=""></div>
                            <div>
                                <div class="font-16-bold">
                                    Email us 
                                </div>
                                <div class="font-16 pt-2">
                                    Business@gtcure.com
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-center pt-3">
                            <div class="pr-3"><img src="../../assets/contact-us/support.png" alt=""></div>
                            <div>
                                <div class="font-16-bold d-flex">
                                    <span>Support</span>
                                </div>
                                <div class="font-16 pt-2">
                                    24/7 Tech
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <app-contact-form></app-contact-form>
            </div>
        </div>
    </div>
</div>
<div class="contact-us-map" style="margin-bottom: -5px;">
    <div style="width: 100%">
        <iframe width="100%" height="500" frameborder="0" scrolling="no" marginheight="0"
         marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=10470%20W%20Cheyenne%20Ave,Suite%20115,+(asda)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
        </iframe>
    </div>
</div>
