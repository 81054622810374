import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login-poup',
  templateUrl: './login-poup.component.html',
  styleUrls: ['./login-poup.component.scss']
})
export class LoginPoupComponent implements OnInit {

  constructor(public bsModelRef: BsModalRef, private router: Router) { }

  ngOnInit(): void {
  }

  close(){
    this.bsModelRef.hide();
  }

  goto(link:any){
    window.open(link, '_blank');
  }
}
