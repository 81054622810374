
<section class="bg-default section-lg talkto-area">
    <div class="">
        <div class="row talk-text">
            <div class="col-lg-5 col-md-5 col-sm-12">
                <div class="animate-box">
                    <div class="block-img-decoration talkto-img wow slideInRight"
                        style="visibility: visible; animation-name: slideInRight;">
                        <img class="talkto-img" [src]="imgUrl" alt="" >
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12">
                <div class="animate-box text-box">
                    <div>
                        <h2 class="wow slideInLeft header-font" data-wow-delay="0.15s"
                        style="visibility: visible; animation-delay: 0.15s; animation-name: slideInLeft;">
                        Talk to best doctor’s from the Industry
                        </h2>
                        <div class="my-3"><div class="header-line"></div></div>
                        <p class="wow slideInLeft talkto-text" data-wow-delay="0.25s"
                            style="visibility: visible; animation-delay: 0.25s; animation-name: slideInLeft;">
                            Patients can have audio-video consultation with a healthcare provider from any 
                            specialty of choice at a time that works best for them on a smart
                            device including computer or a smart phone.
                        </p>
                        <div class="button-block group-lg wow fadeIn" data-wow-delay="0.35s"
                        style="visibility: visible; animation-delay: 0.35s; animation-name: fadeIn;">
                            <button class="primary-button" (click)="triggerScrollTo()">View More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>