import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor(private scrollToService: ScrollToService, private router: Router) { }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'viewMore'
    };
    this.scrollToService.scrollTo(config);
  }

  triggerScrollToMobileApp() {
    this.router.navigate(['/home'])
    const config: ScrollToConfigOptions = {
      target: 'mobileAppLinkView'
    };
    setTimeout(() => {
      this.scrollToService.scrollTo(config);
    }, 200);
  }
}
