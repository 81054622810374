<div class="home-area">
    <div class="row home-text">
        <div class="col-lg-6 col-md-6 col-sm-12 left-area">
            <div>
                <div class="cursol-area">
                    <span class="secondary">
                        Consult with your doctor by your choice and speciality from anywhere
                    </span>
                </div>
                <div class="cursol-info mt-3">
                    You can connect with a healthcare provider who has license
                     to practice medicine in your geographical area where physician can 
                     send prescriptions and lab orders electronically to your pharmacy 
                    and lab of your choice. Also, You can request EXPERT OPINION from a specialist located anywhere globally 
                </div>
                <div class="mt-3 d-flex">
                    <div class="mr-4"><button class="primary-button" (click)="openPopup()">Book a consultation</button></div>
                    <div><button class="border-button" (click)="gotoContactUs()">Enquire Now</button></div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="animate-box">
                <div class="block-img-decoration talkto-img wow slideInRight"
                    style="visibility: visible; animation-name: slideInRight;">
                    <img class="talkto-img" src="../../assets/consult-doctor/banner.png" alt="" >
                </div>
            </div>
        </div>
    </div>
</div>
<app-talkto-best-doc [imgUrl]="imgUrl"></app-talkto-best-doc>
<app-services-offered-consult></app-services-offered-consult>
<app-how-telemed-helps></app-how-telemed-helps>
<app-setup-online-app></app-setup-online-app>
<app-mobile-app></app-mobile-app>
<app-need-more-info></app-need-more-info>