import { Component, Input, OnInit } from '@angular/core';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-talkto-best-doc',
  templateUrl: './talkto-best-doc.component.html',
  styleUrls: ['./talkto-best-doc.component.scss']
})
export class TalktoBestDocComponent implements OnInit {
  @Input() imgUrl: any;
  constructor(private scrollService: ScrollService) { }

  ngOnInit(): void {
  }

  triggerScrollTo(){
    this.scrollService.triggerScrollTo();
  }

}
