<div class="services-we-offer" id="viewMore">
    <div class="services-info-area">
        <div class="header-area">
            <div class="header-font">Services we offer to our patients</div>
            <div class="d-flex flex-row justify-content-center py-3">
                <div class="header-line"></div>
            </div>
        </div>
    </div>
    <div class="py-2">
        <div class=" service-info row">
            <div class="col-lg-4 col-md-4 col-sm-12 left-area">
                <div class="">
                    <div class="" (click)="clickedService(item)"  *ngFor="let item of servicesCardList">
                        <div class="p-3 card-area mt-2" [class.selectedCard]="item.cardNo === selectedCard?.cardNo">
                            <div>{{item?.header}} </div>
                        </div>
                        <div class="py-2 mobile-view" *ngIf="item.cardNo === selectedCard?.cardNo">
                            <div class="">
                                <div class="font-16">{{selectedCard?.subheader}} </div>
                                <div class="animate-box mt-3">
                                    <div class="block-img-decoration talkto-img wow slideInRight"
                                        style="visibility: visible; animation-name: slideInRight;">
                                        <img class="talkto-img" [src]="selectedCard?.imgUrl" alt="" >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 left-area laptop-view">
                <div class="">
                    <div class="font-16">{{selectedCard?.subheader}} </div>
                    <div class="animate-box mt-3">
                        <div class="block-img-decoration talkto-img wow slideInRight"
                            style="visibility: visible; animation-name: slideInRight;">
                            <img class="talkto-img" [src]="selectedCard?.imgUrl" alt="" >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>