import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gtcure-benfits',
  templateUrl: './gtcure-benfits.component.html',
  styleUrls: ['./gtcure-benfits.component.scss']
})
export class GtcureBenfitsComponent implements OnInit {

  benfitsList:any = [
    {
      imgUrl: '../../../assets/for-provider/benfits/Vector0.png',
      text: 'HIPAA compliance and secure communications'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector1.png',
      text: 'Patients can set up their own appointments by selecting one of the time slots you made available in calendar.'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector2.png',
      text: 'Use GTCure web-based or mobile applications'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector3.png',
      text: 'Review patient’s history before audio-video consultation'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector4.png',
      text: 'All specialties are welcomed'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector5.png',
      text: 'Ability to send electronic prescriptions to majority of pharmacies nationwide'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector6.png',
      text: 'GTCure provides malpractice insurance to its providers'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector7.png',
      text: 'Send lab orders to most major labs and receive the results when available'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector8.png',
      text: 'Set up your online virtual clinic and share the link with anyone wanting to have consultation with you'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector9.png',
      text: '24/7 tech support'
    },{
      imgUrl: '../../../assets/for-provider/benfits/Vector10.png',
      text: 'Build your own flexible calendar and time slots. Block your days and hours when you do not want to provide consultations.'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
