import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginPoupComponent } from '../login-poup/login-poup.component';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  menusList: any[] = [
    {
      name: "Home",
      navLink: "home",
    },{
      name: "Consult A Doctor",
      navLink: "consult-doctor",
    },{
      name: "For Providers",
      navLink: "for-providers",
    },{
      name: "About Us",
      navLink: "about-us",
    },{
      name: "Contact Us",
      navLink: "contact-us",
    }
  ];
  currentRoute:any;
  bsModelRef:BsModalRef;
  constructor(private router: Router,private zone: NgZone,
              private bsModelService:BsModalService, 
              private scrollService: ScrollService) { }

  ngOnInit(): void {
  }

  activeLink(routerLink:any){
    this.currentRoute = this.router.url;
    if(this.currentRoute.includes(routerLink)){
      return true;
    }else{
      return false;
    }
  }

  menuClicked(data){
    this.router.navigate([`/${data?.navLink}`]).then(() => {
      window.location.reload();
    });
  }

  openPopup(){
    this.bsModelRef = this.bsModelService.show(LoginPoupComponent, {class: 'modal-md modal-dialog-centered'});
  }

  gotoMobile(){
    this.scrollService.triggerScrollToMobileApp();
  }
}
