import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-offered-consult',
  templateUrl: './services-offered-consult.component.html',
  styleUrls: ['./services-offered-consult.component.scss']
})
export class ServicesOfferedConsultComponent implements OnInit {

  servicesCardList:any[] = [
    {
      header: 'Sign up & save medical history',
      subheader: `Once patients sign up, its highly encouraged to
       take a few moments and complete the “patient intake form” 
       this will help make consultation efficient and smooth.`,
      imgUrl: '../../../assets/consult-doctor/1.svg',
      cardNo: 1
    },{
      header: 'Select a pharmacy',
      subheader:`You can select a pharmacy most convenient for you and healthcare provider 
      can send prescription electronically to the pharmacy of your choice.`,
      imgUrl: '../../../assets/consult-doctor/2.svg',
      cardNo: 2
    },{
      header: 'Find a physician',
      subheader: `Patients can look up a desired providers with their name, 
      specialty and geographical area and time zone. Patients can also look up their profiles and select the provider
       based on their needs including language preference. You can use your
        health insurance or pay out of pocket for your visit.`,
      imgUrl: '../../../assets/consult-doctor/3.svg',
      cardNo: 3
    },{
      header: 'Set up online appointment',
      subheader: `You can look up time slots available for consultation
       by directly viewing the provider’s schedule. You can pick a time slot that
        works best for your needs. You can have almost immediate online consultation or
         can set up consultation for a later day and time that works best for you.`,
      imgUrl: '../../../assets/consult-doctor/4.svg',
      cardNo: 4
    },{
      header: 'Medication',
      subheader: `A healthcare provider can send prescription electronically to your desired pharmacy location.`,
      imgUrl: '../../../assets/consult-doctor/5.svg',
      cardNo: 5
    },{
      header: 'Lab and Radiology Order',
      subheader: `GTCure has capability of sending lab and radiology orders
       electronically to most major labs and select radiology centers nationwide.
       Your physician will also have access to your test results online when available.`,
      imgUrl: '../../../assets/consult-doctor/6.svg',
      cardNo: 6
    },
  ];
  selectedCard: any = this.servicesCardList[0];
  constructor() { }

  ngOnInit(): void {
  }

  clickedService(item:any){
    this.selectedCard = item;
  }

}
