<div class="how-tm-help">
    <div class="how-tm-help-area">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 mt-3 service-card d-flex"
                     *ngFor="let card of dataList">
                        <div class="card-title-area" *ngIf="card.reason === 'title' ">
                            <div class="header-area">
                                <div class="header-font">How Telemedicine helps patients</div>
                                <div class="py-3">
                                    <div class="header-line"></div>
                                </div>
                            </div>
                        </div>
                        <div class="card"  *ngIf="card.number !== 'dummy' ">
                            <div class="card-body">
                                <div class="">
                                    <div class="font-18-bold">{{card.number}}. </div>
                                    <div class="card-subHeader hideOnMouseOver font-16">{{card.text}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card  display-none" hidden="true" *ngIf="card.number === 'hide' ">
                            
                        </div>
             </div>
        </div>
    </div>
</div>