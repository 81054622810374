import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-telemed-helps',
  templateUrl: './how-telemed-helps.component.html',
  styleUrls: ['./how-telemed-helps.component.scss']
})
export class HowTelemedHelpsComponent implements OnInit {

  dataList: any = [
    {
      number: 'dummy',
      reason: 'title'
    },{
      number: '01',
      text: `Telemedicine helps you connect with health experts of
      your choice with audio and video conference, thereby help save you valuable time, energy and cost.`
    },{
      number: '02',
      text: `Telemedicine provides Immediate access to a healthcare provider at convenience of your own location.`
    },
    {
      number: 'dummy',
      reason: 'hide'
    },{
      number: '03',
      text: `You save the time and travel expense required for a commute for in-person office visit.`
    },{
      number: '04',
      text: `Avoid long wait time in office waiting areas.`
    },{
      number: '05',
      text: `Minimize disturbance in your daily schedule and commitments by reducing in-person office visits.`
    },{
      number: '06',
      text: `Minimize exposure to other people around you in the setting of Covid 19 pandemic.`
    },{
      number: '07',
      text: `Get a second opinion from a medical expert of your choice from anywhere globally when 
      you are not fully satisfied with the assessment of a healthcare provider you have consulted previously.`
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
