import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-we-offer',
  templateUrl: './services-we-offer.component.html',
  styleUrls: ['./services-we-offer.component.scss']
})
export class ServicesWeOfferComponent implements OnInit {

  servicesCardList:any[] = [
    {
      header: 'Sign up & save medical history',
      subheader: 'Sign up at GTCure for consultations in a few easy steps',
      mouserOverText: `Once patients sign up, its highly encouraged to take a few moments and complete the “patient intake form” this will help make consultation efficient and smooth.`,
      imgUrl: '../../assets/home/1.svg',
      isBlueCard: false
    },{
      header: 'Select a pharmacy',
      subheader: 'You can select a pharmacy most convenient for you.',
      mouserOverText: `You can select a pharmacy most convenient for you and healthcare provider can send prescription electronically to the pharmacy of your choice.`,
      imgUrl: '../../assets/home/2.svg',
      isBlueCard: false
    },{
      header: 'Find a physician',
      subheader: 'Look up a healthcare practitioner by specialty, name or location',
      mouserOverText: `Patients can look up a desired providers with their name, specialty and geographical area and time zone. Patients can also look up their profiles and select the provider based on their needs including language preference. You can use your health insurance or pay out of pocket for your visit.`,
      imgUrl: '../../assets/home/3.svg',
      isBlueCard: false
    },{
      header: 'Set up online appointment',
      mouserOverText: `You can look up time slots available for consultation by directly viewing the provider’s schedule. You can pick a time slot that works best for your needs.
         You can have almost immediate online consultation or can set up
        consultation for a later day and time that works best for you.`,
      imgUrl: '../../assets/home/4.svg',
      subheader: `You can book a time slots for consultation according to you..`,
      isBlueCard: true
    },{
      header: 'Medication',
      subheader: ' Healthcare providers can send medications prescriptions',
      imgUrl: '../../assets/home/5.svg',
      mouserOverText: `A healthcare provider can send prescription electronically to your desired pharmacy location.`,
      isBlueCard: false
    },{
      header: 'Lab and Radiology Order',
      subheader: 'Healthcare practitioners at GTCure can electronically send lab',
      imgUrl: '../../assets/home/6.svg',
      mouserOverText: `GTCure has capability of sending lab and radiology orders electronically to most major labs and select radiology centers nationwide. Your physician will also have access to your test results online when available.`,
      isBlueCard: false
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
