<div class="setup-online">
    <div class="setup-online-area">
        <div class="header-area">
            <div class="header-font">GTCure benefits for providers</div>
            <div class="py-3">
                <div class="header-line"></div>
            </div>
        </div>
        <div class="mt-4">
            <div class="row">
                <div class="col-md-6" *ngFor="let item of benfitsList">
                    <div class="d-flex my-2">
                        <div class="mr-2"><img [src]="item.imgUrl" alt=""></div>
                        <div class="font-16">{{item.text}} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>