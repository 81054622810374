<div class="form" [ngClass]="isFromHome ? 'forHome' : 'white-bg'">
    <form action="" class="w-100" #f="ngForm"  (ngSubmit)="submit(f)">
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Purpose of Email</mat-label>
                <mat-select [(ngModel)]="contactUsForm.purpose" #purpose='ngModel' name="purpose" required>
                    <mat-option *ngFor="let option of purposeList" [value]="option">{{option}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.submitted && purpose.invalid">
                    <mat-error *ngIf="purpose.errors.required">Purpose of Email is required</mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="contactUsForm.name" #name='ngModel' name="name" placeholder="Name" required>
                <mat-error *ngIf="f.submitted && name.invalid">
                    <mat-error *ngIf="name.errors.required">name is required</mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" [(ngModel)]="contactUsForm.email" name="email" #email='ngModel' required>
                <mat-error *ngIf="f.submitted && email.invalid">
                    <mat-error *ngIf="email.errors.required">Email is required</mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Type your message here</mat-label>
                <textarea matInput placeholder="Type your message here" name="message"
                 [(ngModel)]="contactUsForm.message" #message='ngModel' required></textarea>
                <mat-error *ngIf="f.submitted && message.invalid">
                    <mat-error *ngIf="message.errors.required">Type your message here is required</mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <div class=" mb-2">
                <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="contactUsForm.token"
                required [class.is-invalid]="f.submitted && recaptcha.invalid"></re-captcha>
                <div *ngIf="f.submitted && recaptcha.invalid">
                  <div class="error-info" *ngIf="recaptcha.errors?.required">Recaptcha is required.</div>
                </div>
            </div>
        </div>
        <div class="text-align-center mt-3">
            <button type="submit" class="primary-button">Submit</button>
        </div>
    </form>
</div>