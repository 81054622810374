import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { MainPageComponent } from './main-page/main-page.component';
import { HomeComponent } from './home/home.component';
import { TalktoBestDocComponent } from './talkto-best-doc/talkto-best-doc.component';
import { ServicesWeOfferComponent } from './services-we-offer/services-we-offer.component';
import { MobileAppComponent } from './mobile-app/mobile-app.component';
import { FooterComponent } from './footer/footer.component';
import { NeedMoreInfoComponent } from './need-more-info/need-more-info.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { SetupOnlineAppComponent } from './setup-online-app/setup-online-app.component';
import { ConsultDoctorPageComponent } from './consult-doctor-page/consult-doctor-page.component';
import { ServicesOfferedConsultComponent } from './consult-doctor-page/services-offered-consult/services-offered-consult.component';
import { HowTelemedHelpsComponent } from './consult-doctor-page/how-telemed-helps/how-telemed-helps.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { ForProviderPageComponent } from './for-provider-page/for-provider-page.component';
import { StartConsulationComponent } from './for-provider-page/start-consulation/start-consulation.component';
import { GtcureBenfitsComponent } from './for-provider-page/gtcure-benfits/gtcure-benfits.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurFounderComponent } from './about-us/our-founder/our-founder.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LoginPoupComponent } from './login-poup/login-poup.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    HomeComponent,
    TalktoBestDocComponent,
    ServicesWeOfferComponent,
    MobileAppComponent,
    FooterComponent,
    NeedMoreInfoComponent,
    ContactFormComponent,
    SetupOnlineAppComponent,
    ConsultDoctorPageComponent,
    ServicesOfferedConsultComponent,
    HowTelemedHelpsComponent,
    ForProviderPageComponent,
    StartConsulationComponent,
    GtcureBenfitsComponent,
    AboutUsComponent,
    OurFounderComponent,
    ContactUsComponent,
    LoginPoupComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MaterialModule,
    ModalModule.forRoot(),
    ScrollToModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6Lfkw5AfAAAAAC-_EgBSxM45Cgw0H9XPytQf7s7G',
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
