<div class="setup-online">
    <div class="setup-online-area">
        <div class="header-area">
            <div class="header-font">Terms & Conditions</div>
            <div class="py-3">
                <div class="header-line"></div>
            </div>
        </div>
        <div class="info-area mt-3">
            <div class="header-sub-font">Terms and Conditions (&quot;Terms&quot;)</div>
            <div class="font-16 mt-2">
                Our Privacy Policy was last updated on June 01, 2022 
            </div>
            <div class="font-16 mt-2">
                Please read these terms and conditions carefully before using Our Service.
            </div>
            <div class="header-sub-font mt-2 ">Interpretation and Definitions</div>
            <div class="font-18-bold mt-2">Interpretation</div>
            <div class="font-16 mt-2">
                The words of which the initial letter is capitalized have meanings defined under the following conditions.
                The following definitions shall have the same meaning regardless of whether they appear in singular or in
                plural.
            </div>
            <div class="font-18-bold mt-2">Definitions</div>
            <div class="font-16 mt-2">For the purposes of these Terms and Conditions:</div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Affiliate” means an entity that controls, is controlled by or is under common control with a party,
                        where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities
                        entitled to vote for election of directors or other managing authority.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Account” means a unique account created for You to access our Service or parts of our Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Company” (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to
                        GTCure LLC
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Country” refers to United States of America
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Content” refers to content such as text, images, or other information that can be posted, uploaded,
                        linked to or otherwise made available by You, regardless of the form of that content.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Device” means any device that can access the Service such as a computer, a cellphone, or a
                        digital tablet.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Feedback” means feedback, innovations or suggestions sent by You regarding the attributes,
                        performance or features of our Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Products” refer to the products or items offered for sale on the Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Orders” mean a request by You to purchase Products from Us.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Promotions” refer to contests, sweepstakes or other promotions offered through the Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Service” refers to the Website.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Terms and Conditions” (also referred as &quot;Terms&quot;) mean these Terms and Conditions that form
                        the entire agreement between You and the Company regarding the use of the Service. This Terms
                        and Conditions Agreement was generated by Terms Feed Terms and Conditions Generator.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Service Provider" means any natural or legal person who processes the data on behalf of the
                        Company. It refers to third-party companies or individuals employed by the Company to facilitate the
                        Service, to provide the Service on behalf of the Company, to perform services related to the Service
                        or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR,
                        Service Providers are considered Data Processors.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        “Third-party Social Media Service” means any services or content (including data, information,
                        products or services) provided by a third-party that may be displayed, included or made available by
                        the Service.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "Website" refers to <a href="www.GTCure.com">www.GTCure.com</a> ,
                         accessible from <a href="https://www.gtcure.com/">https://www.gtcure.com/</a>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        "You" means the individual accessing or using the Service, or the company, or other legal entity on
                        behalf of which such individual is accessing or using the Service, as applicable.
                    </div>
                </div>
            </div>
            <div class="header-sub-font mt-3">Acknowledgment</div>
            <div class="font-16 mt-2">
                These are the Terms and Conditions governing the use of this Service and the agreement that operates
                between You and the Company. These Terms and Conditions set out the rights and obligations of all users
                regarding the use of the Service.
            </div>
            <div class="font-16 mt-2">
                Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
                Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or
                use the Service.
            </div>
            <div class="font-16 mt-2">
                By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree
                with any part of these Terms and Conditions, then You may not access the Service.
            </div>
            <div class="font-16 mt-2">
                You represent that you are over the age of 18. The Company does not permit those under 18 to use the
                Service.
            </div>
            <div class="font-16 mt-2">
                Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the
                Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection,
                use and disclosure of Your personal information when You use the Application or the Website and tells You
                about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before
                using Our Service.
            </div>
            <div class="header-sub-font mt-3">User Accounts</div>
            <div class="font-16 mt-2">
                When You create an account with Us, You must provide Us information that is accurate, complete, and
                current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                termination of Your account on Our Service.
            </div>
            <div class="font-16 mt-2">
                You are responsible for safeguarding the password that You use to access the Service and for any
                activities or actions under Your password, whether Your password is with Our Service or a Third-Party
                Social Media Service.
            </div>
            <div class="font-16 mt-2">
                You agree not to disclose Your password to any third party. You must notify Us immediately upon
                becoming aware of any breach of security or unauthorized use of Your account.
            </div>
            <div class="font-16 mt-2">
                You may not use as a username the name of another person or entity or that is not lawfully available for
                use, a name or trademark that is subject to any rights of another person or entity other than You without
                appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
            </div>
            <div class="header-sub-font mt-3">Content</div>
            <div class="font-18-bold mt-2">
                Your Right to Post Content
            </div>
            <div class="font-16 mt-2">
                Our Service allows You to post Content. You are responsible for the Content that You post to the Service,
                including its legality, reliability, and appropriateness.
            </div>
            <div class="font-16 mt-2">
                By posting Content to the Service, you grant Us the right and license to use, modify, publicly perform,
                publicly display, reproduce, and distribute such Content on and through the Service. You retain all of Your
                rights to any Content You submit, post or display on or through the Service and You are responsible for
                protecting those rights. You agree that this license includes the right for Us to make Your Content available
                to other users of the Service, who may also use Your Content subject to these Terms.
            </div>
            <div class="font-16 mt-2">
                You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and
                grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or
                through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any
                other rights of any person.
            </div>
            <div class="font-18-bold mt-2">
                Content Restrictions
            </div>
            <div class="font-16 mt-2">
                The Company is not responsible for the content of the Service&#39;s users. You expressly understand and
                agree that You are solely responsible for the Content and for all activity that occurs under your account,
                whether done so by You or any third person using Your account.
            </div>
            <div class="font-16 mt-2">
                You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening,
                libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include,
                but are not limited to, the following:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Unlawful or promoting unlawful activity.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Defamatory, discriminatory, or mean-spirited content, including references or commentary about
                        religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising,
                        chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Containing or installing any viruses, worms, malware, trojan horses, or other content that is
                        designed or intended to disrupt, damage, or limit the functioning of any software, hardware or
                        telecommunications equipment or to damage or obtain unauthorized access to any data or other
                        information of a third person.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright,
                        right of publicity or other rights.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Impersonating any person or entity including the Company and its employees or representatives.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Violating the privacy of any third person.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        False information and features.
                    </div>
                </div>
            </div>
            <div class="font-16 mt-2">
                The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not
                any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company
                further reserves the right to make formatting and edits and change the manner of any Content. The
                Company can also limit or revoke the use of the Service if You post such objectionable Content. As the
                Company cannot control all content posted by users and/or third parties on the Service, you agree to use
                the Service at your own risk. You understand that by using the Service You may be exposed to content that
                You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances
                will the Company be liable in any way for any content, including any errors or omissions in any content, or
                any loss or damage of any kind incurred as a result of your use of any content.
            </div>
            <div class="font-18-bold mt-2">
                Content Backups
            </div>
            <div class="font-16 mt-2">
                Although regular backups of Content are performed, the Company does not guarantee there will be no loss
                or corruption of data.
            </div>
            <div class="font-16 mt-2">
                Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to
                being backed up or that changes during the time a backup is performed.
            </div>
            <div class="font-16 mt-2">
                The Company will provide support and attempt to troubleshoot any known or discovered issues that may
                affect the backups of Content. But You acknowledge that the Company has no liability related to the
                integrity of Content or the failure to successfully restore Content to a usable state.
            </div>
            <div class="font-16 mt-2">
                You agree to maintain a complete and accurate copy of any Content in a location independent of the
                Service.
            </div>
            <div class="header-sub-font mt-3">Copyright Policy</div>
            <div class="font-18-bold mt-2">
                Intellectual Property Infringement
            </div>
            <div class="font-16 mt-2">
                We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content
                posted on the Service infringes a copyright or other intellectual property infringement of any person.
            </div>
            <div class="font-16 mt-2">
                If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has
                been copied in a way that constitutes copyright infringement that is taking place through the Service, You
                must submit Your notice in writing to the attention of our copyright agent via email
                 (<a href="Support@GTCure.com">Support@GTCure.com</a> )
                and include in Your notice a detailed description of the alleged infringement.
            </div>
            <div class="font-16 mt-2">
                You may be held accountable for damages (including costs and attorneys&#39; fees) for misrepresenting that
                any Content is infringing Your copyright.
            </div>
            <div class="font-18-bold mt-2">
                DMCA Notice and DMCA Procedure for Copyright Infringement Claims
            </div>
            <div class="font-16 mt-2">
                You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our
                Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        An electronic or physical signature of the person authorized to act on behalf of the owner of the
                        copyright&#39;s interest.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        A description of the copyrighted work that You claim has been infringed, including the URL (i.e.,
                        web page address) of the location where the copyrighted work exists or a copy of the copyrighted
                        work. Defamatory, discriminatory, or mean-spirited content, including references or commentary about
                        religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Identification of the URL or other specific location on the Service where the material that You claim
                        is infringing is located.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Your address, telephone number, and email address.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        A statement by You that You have a good faith belief that the disputed use is not authorized by the
                        copyright owner, its agent, or the law.
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        A statement by You, made under penalty of perjury, that the above information in Your notice is
                        accurate and that You are the copyright owner or authorized to act on the copyright owner&#39;s behalf.
                    </div>
                </div>
            </div>
            <div class="font-16 mt-2">
                You can contact our copyright agent via email (<a href="Support@GTCure.com">Support@GTCure.com</a> ). Upon receipt of a notification, the
                Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the
                challenged content from the Service.
            </div>
            <div class="header-sub-font mt-3">Using Our Site</div>
            <div class="font-16 mt-2">
                By using our site for the Service, You warrant that You are legally capable of entering into binding
                contracts.
            </div>
            <div class="font-18-bold mt-2">
                Your Information
            </div>
            <div class="font-16 mt-2">
                If You wish to place an Order for Products available on the Service, You may be asked to supply certain
                information relevant to Your Order including, without limitation, Your name, Your email, Your phone
                number, Your credit card number, the expiration date of Your credit card, Your billing address, and Your
                shipping information.
            </div>
            <div class="font-16 mt-2">
                You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other
                payment method(s) in connection with any Order; and that (ii) the information You supply to us is true,
                correct and complete.
            </div>
            <div class="font-16 mt-2">
                By submitting such information, You grant us the right to provide the information to payment processing
                third parties for purposes of facilitating the completion of Your Order.
            </div>
            <div class="font-18-bold mt-2">
                Order Cancellation
            </div>
            <div class="font-16 mt-2">
                We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not limited
                to:
            </div>
            <div class="font-16 mt-2">
                We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is
                suspected.
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Products availability
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Errors in the description or prices for Products
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        Errors in Your Order
                    </div>
                </div>
            </div>
            <div class="font-18-bold mt-2">
                Your Cancellation Rights
            </div>
            <div class="font-16 mt-2">
                Any service you purchase can only be returned or refunded in accordance with these Terms and
                Conditions.
            </div>
            <div class="font-18-bold mt-2">
                Availability, Errors and Inaccuracies
            </div>
            <div class="font-16 mt-2">
                We are constantly updating Our offerings of Products on the Service. The Products available on Our
                Service may be mispriced, described inaccurately, or unavailable, and We may experience delays in
                updating information regarding our Products on the Service and in Our advertising on other websites.
            </div>
            <div class="font-16 mt-2">
                We cannot and do not guarantee the accuracy or completeness of any information, including prices,
                product images, specifications, availability, and services. We reserve the right to change or update
                information and to correct errors, inaccuracies, or omissions at any time without prior notice.
            </div>
            <div class="font-18-bold mt-2">
                Prices Policy
            </div>
            <div class="font-16 mt-2">
                The Company reserves the right to revise its prices at any time prior to accepting an Order.
            </div>
            <div class="font-16 mt-2">
                The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any
                occurrence affecting delivery caused by government action, variation in customs duties, increased shipping
                charges, higher foreign exchange costs and any other matter beyond the control of the Company. In that
                event, You will have the right to cancel Your Order.
            </div>
            <div class="font-18-bold mt-2">
                Payments
            </div>
            <div class="font-16 mt-2">
                Payment can be made through various payment methods we have available, such as Visa, MasterCard,
                Affinity Card, American Express cards or online payment methods (PayPal, for example).
            </div>
            <div class="font-16 mt-2">
                Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your card
                issuer. If we do not receive the required authorization, We will not be liable for any delay or non-processing
                of Your Order.
            </div>
            <div class="header-sub-font mt-3">Intellectual Property</div>
            <div class="font-16 mt-2">
                The Service and its original content (excluding Content provided by You or other users), features and
                functionality are and will remain the exclusive property of the Company and its licensors.
            </div>
            <div class="font-16 mt-2">
                The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.
            </div>
            <div class="font-16 mt-2">
                Our trademarks and trade dress may not be used in connection with any product or service without the
                prior written consent of the Company.
            </div>
            <div class="header-sub-font mt-3">Your Feedback to Us</div>
            <div class="font-16 mt-2">
                You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such
                assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty
                free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit
                such Feedback without restriction.
            </div>
            <div class="header-sub-font mt-3">Links to Other Websites</div>
            <div class="font-16 mt-2">
                Our Service may contain links to third-party web sites or services that are not owned or controlled by the
                Company.
            </div>
            <div class="font-16 mt-2">
                The Company has no control over, and assumes no responsibility for, the content, privacy policies, or
                practices of any third-party web sites or services. You further acknowledge and agree that the Company
                shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with the use of or reliance on any such content, goods or services available on
                or through any such web sites or services.
            </div>
            <div class="font-16 mt-2">
                We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or
                services that You visit.
            </div>
            <div class="header-sub-font mt-3">Termination</div>
            <div class="font-16 mt-2">
                We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason
                whatsoever, including without limitation if You breach these Terms and Conditions.
            </div>
            <div class="font-16 mt-2">
                Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your
                Account, You may simply discontinue using the Service.
            </div>
            <div class="header-sub-font mt-3">Limitation of Liability</div>
            <div class="font-16 mt-2">
                Notwithstanding any damages that You might incur, the entire liability of the Company and any of its
                suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be
                limited to the amount actually paid by You through the Service or 100 USD if You haven&#39;t purchased
                anything through the Service.
            </div>
            <div class="font-16 mt-2">
                To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable
                for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to,
                damages for loss of profits, loss of data or other information, for business interruption, for personal injury,
                loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party
                software and/or third-party hardware used with the Service, or otherwise in connection with any provision of
                this Terms), even if the Company or any supplier has been advised of the possibility of such damages and
                even if the remedy fails of its essential purpose.
            </div>
            <div class="font-16 mt-2">
                Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
                consequential damages, which means that some of the above limitations may not apply. In these states,
                each party&#39;s liability will be limited to the greatest extent permitted by law.
            </div>
            <div class="header-sub-font mt-3">&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</div>
            <div class="font-16 mt-2">
                The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without
                warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own
                behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly
                disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service,
                including all implied warranties of merchantability, fitness for a particular purpose, title and non-
                infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade
                practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes
                no representation of any kind that the Service will meet Your requirements, achieve any intended results,
                be compatible or work with any other software, applications, systems or services, operate without
                interruption, meet any performance or reliability standards or be error free or that any errors or defects can
                or will be corrected.
            </div>
            <div class="font-16 mt-2">
                Without limiting the foregoing, neither the Company nor any of the company&#39;s provider makes any
                representation or warranty of any kind, express or implied: (i) as to the operation or availability of the
                Service, or the information, content, and materials or products included thereon; (ii) that the Service will be
                uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content
                provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on
                behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other
                harmful components.
            </div>
            <div class="font-16 mt-2">
                Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
                statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You.
            </div>
            <div class="font-16 mt-2">
                But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest
                extent enforceable under applicable law.
            </div>
            <div class="header-sub-font mt-3">Governing Law</div>
            <div class="font-16 mt-2">
                The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the
                Service. Your use of the Application may also be subject to other local, state, national, or international laws.
            </div>
            <div class="header-sub-font mt-3">Disputes Resolution</div>
            <div class="font-16 mt-2">
                If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally
                by contacting the Company.
            </div>
            <div class="header-sub-font mt-3">Severability and Waiver</div>
            <div class="font-18-bold mt-2">Severability</div>
            <div class="font-16 mt-2">
                If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
                interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
                law and the remaining provisions will continue in full force and effect.
            </div>
            <div class="font-18-bold mt-2">Waiver</div>
            <div class="font-16 mt-2">
                Except as provided herein, the failure to exercise a right or to require performance of an obligation under
                this Terms shall not effect a party&#39;s ability to exercise such right or require such performance at any time
                thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
            </div>
            <div class="header-sub-font mt-3">Changes to These Terms and Conditions</div>
            <div class="font-16 mt-2">
                We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is
                material We will make reasonable efforts to provide at least 30 days&#39; notice prior to any new terms taking
                effect. What constitutes a material change will be determined at Our sole discretion.
            </div>
            <div class="font-16 mt-2">
                By continuing to access or use Our Service after those revisions become effective, You agree to be bound
                by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the
                website and the Service.
            </div>
            <div class="header-sub-font mt-3">
                Contact Us
            </div>
            <div class="font-16 mt-2">
                If you have any questions about these Terms and Conditions, You can contact us:
            </div>
            <div class="mt-2">
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        By visiting this page on our website: <a href="https://www.gtcure.com/contact-us">https://www.gtcure.com/contact-us</a>
                    </div>
                </div>
                <div class="sub-text-points mt-3">
                    <div class="orange-dot mr-3"></div>
                    <div class="width-80">
                        By sending us an email: <a href="Business@GTCure.com">Business@GTCure.com</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>