import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-for-provider-page',
  templateUrl: './for-provider-page.component.html',
  styleUrls: ['./for-provider-page.component.scss']
})
export class ForProviderPageComponent implements OnInit {

  constructor( private router: Router) { }

  ngOnInit(): void {
  }

  gotoContactUs(){
    this.router.navigate(['/contact-us']);
  }

}
