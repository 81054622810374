import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-consulation',
  templateUrl: './start-consulation.component.html',
  styleUrls: ['./start-consulation.component.scss']
})
export class StartConsulationComponent implements OnInit {

  step:string = 'step2';
  
  constructor() { }

  ngOnInit(): void {
  }

  stepClicked(stepName:string){
    this.step = stepName;
  }
}
