import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup-online-app',
  templateUrl: './setup-online-app.component.html',
  styleUrls: ['./setup-online-app.component.scss']
})
export class SetupOnlineAppComponent implements OnInit {
  step:string = 'step2';
  
  constructor() { }

  ngOnInit(): void {
  }

  stepClicked(stepName:string){
    this.step = stepName;
  }
}
