<div class="gtcure-website">
    <div class="navbar-area">
        <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
            <a class="navbar-brand">
                <img src="../../assets/home/logo.svg" alt="">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                <!-- <span class="navbar-toggler-icon"></span> -->
                <img src="../../assets/menu.svg" alt="">
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                <li class="nav-item" (click)="menuClicked(item)"  *ngFor="let item of menusList" [class.customActive]="activeLink(item.navLink)">
                  <a class="nav-link">{{item.name}}</a>
                </li>
                <li class="nav-item ml-2 hideMobileView" (click)="openPopup()">
                    <a class="nav-link login-link">Login</a>
                </li>
                <li class="nav-item hideMobileView">
                    <a class="nav-link" (click)="openPopup()"> 
                        <button class="primary-button">Sign Up</button>
                    </a>
                </li>
                <li class="nav-item ml-2 showMobileView" (click)="gotoMobile()">
                  <a class="nav-link login-link">Login</a>
                </li>
                <li class="nav-item showMobileView">
                    <a class="nav-link" (click)="gotoMobile()"> 
                        <button class="primary-button">Sign Up</button>
                    </a>
                </li>
              </ul>
            </div>
          </nav>
    </div>
</div>
