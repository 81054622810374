<div class="modal-body">
    <div style="float: right; cursor: pointer;" class="cursor-pointer" (click)="close()">
      <img src="../../assets/Close.svg" alt="">
    </div>
    <div>
        <div class="d-flex align-center text-center justify-content-center">
            <div>
                <div class="font-18-bold">Login or Sign up</div>
                <div class="font-16">Please select one below to login.</div>
            </div>
        </div>
        <div class="mt-3 d-flex align-center justify-content-center"><img src="../../assets/login-img.png" alt=""></div>
        <div class="pt-3">
            <div class="p-3 text-box" (click)="goto('https://app.gtcure.com/doctor/')">
                <div class="font-16-bold">I am a Doctor</div>
                <div>I provide consultation</div>
            </div>
            <div class="p-3 mt-2 text-box" (click)="goto('https://app.gtcure.com/patient/')">
                <div class="font-16-bold">I am a Patient</div>
                <div>Book appointment with doctor</div>
            </div>
        </div>
    </div>
</div>