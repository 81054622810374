<div class="need-more-info">
    <div class="need-more-area row pt-4">
        <div class="col-lg-6 col-md-6 col-sm-12 left-img">
            <div class="animate-box">
                <div class="block-img-decoration talkto-img wow slideInRight"
                    style="visibility: visible; animation-name: slideInRight;">
                    <img class="talkto-img" src="../../assets/home/contactform-left.svg" alt="" >
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="py-4">
                <div class="pb-4">
                    <div class="header-font">Need more information? Let us help you</div>
                </div>
                <app-contact-form [isFromHome]="true"></app-contact-form>
            </div>
        </div>
    </div>
</div>