import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginPoupComponent } from '../login-poup/login-poup.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  imgUrl: string = '../../assets/home/Talk-best-dr.svg';
  bsModelRef:BsModalRef;
  constructor(private bsModelService:BsModalService, private router: Router) { }

  ngOnInit(): void {
  }

  openPopup(){
    this.bsModelRef = this.bsModelService.show(LoginPoupComponent, {class: 'modal-md modal-dialog-centered'});
  }

  gotoContactUs(){
    this.router.navigate(['/contact-us']);
  }

}
