import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginPoupComponent } from '../login-poup/login-poup.component';

@Component({
  selector: 'app-consult-doctor-page',
  templateUrl: './consult-doctor-page.component.html',
  styleUrls: ['./consult-doctor-page.component.scss']
})
export class ConsultDoctorPageComponent implements OnInit {
  imgUrl: string = "../../assets/consult-doctor/talk-to-best-doc.svg";
  bsModelRef:BsModalRef;
  constructor(private bsModelService:BsModalService, private router: Router) { }

  ngOnInit(): void {
  }

  openPopup(){
    this.bsModelRef = this.bsModelService.show(LoginPoupComponent, {class: 'modal-md modal-dialog-centered'});
  }

  gotoContactUs(){
    this.router.navigate(['/contact-us']);
  }
}
